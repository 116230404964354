/*
* Copyright (C) WeAstronauts Software - All Rights Reserved 2022.
* Unauthorized copying of this file, via any medium is strictly prohibited
* Proprietary and confidential
*/

import { RootEpic } from "src/app/store/root.epic";
import { isActionOf } from "typesafe-actions";
import {
	uiApplyDiscountCode,
	uiCalculateAdminRoomReservationPrice,
	uiCalculateReservationPrice,
	uiCalculateReservationProductsPrice,
	uiCreateAdminReservation,
	uiCreateReservation,
	uiDeleteReservation,
	uiFetchAdminReservationsByDate,
	uiFetchAdminRoomAvailabilities,
	uiFetchAdminRoomAvailabilitiesDebounce,
	uiFetchReservationById,
	uiFetchRoomAvailabilities,
	uiGenerateReservationInvoice,
	uiRemoveDiscountCode,
	uiReservationInit,
	uiSendReservationInvoice,
	uiUpdateReservation,
	uiUpdateReservationStatus,
} from "src/app/store/features/ui/reservation/ui.reservation.actions";
import { debounceTime, filter, map, mergeMap, switchMap, take } from "rxjs/operators";
import { concat, merge, of } from "rxjs";
import { addLoadingRecord, removeLoadingRecord } from "src/app/store/features/ui/loading/ui.loading.actions";
import { LoadableType, LoadingRecord } from "src/app/types/ui/loading.types";
import { calculateAdminRoomReservationPriceAsync, calculateRoomReservationPriceAsync, createAdminReservationAsync, createReservationAsync, deleteReservationByIdAsync, fetchAdminReservationsByDateAsync, fetchAdminRoomAvailabilitiesAsync, fetchReservationByIdAsync, fetchRoomAvailabilitiesAsync, generateReservationInvoiceAsync, sendReservationInvoiceAsync, updateReservationAsync, updateReservationStatusAsync } from "src/app/store/features/reservation/reservation.actions";
import { displayToast } from "src/app/store/features/message/message.actions";
import { ToastType } from "src/app/types/ui/message.types";
import { purchasingProcessFormActions, roomReservationFormActions, updateReservationFormActions } from "src/app/store/features/form/form.actions";
import { fetchAdminVenueAvailabilitiesAsync, fetchVenuesAsync } from "src/app/store/features/venue/venue.actions";
import { fetchProductsAsync } from "src/app/store/features/product/product.actions";
import { getVenueIdFromURL, mapPersistedProductsToProducts, purchasingProcessFormInitialState } from "src/app/utils/constants/purchasingProcess.form";
import { DateTime } from "luxon";
import { uiFetchVenueAvailabilitiesDebounce } from "src/app/store/features/ui/venue/ui.venue.actions";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { Nullable } from "src/app/types/util.types";
import { SimpleVenue } from "src/app/types/api/venue.types";
import { replace } from "redux-first-history";
import { purchasingProcessFormStepRouteDictionary, reservationStatusDictionary } from "src/app/utils/constants/dictionaries";
import { DiscountCodePayload, PurchasingProcessFormStep, ReservationDiscountCode } from "src/app/types/api/reservation.types";
import { DiscountCodeType } from "src/app/types/api/discountCode.types";
import { fetchCategoriesAsync } from "src/app/store/features/category/category.actions";
import { LocaleFromISO } from "src/app/utils/luxon";
import { ErrorCodeName } from "src/app/types/api/api.types";
import TagManager from "react-gtm-module";
import moment from "moment";

export const uiReservationInitEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiReservationInit)),
		switchMap(_ => {
			const loadingRecord = { loadableType: LoadableType.RESERVATION_INIT };
			const fetchVenuesLoadingRecord: LoadingRecord = { loadableType: LoadableType.FETCH_VENUES };
			const fetchProductsLoadingRecord: LoadingRecord = { loadableType: LoadableType.FETCH_PRODUCTS };
			const fetchCategoriesLoadingRecord: LoadingRecord = { loadableType: LoadableType.FETCH_CATEGORIES };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(addLoadingRecord(fetchVenuesLoadingRecord)),
				of(addLoadingRecord(fetchProductsLoadingRecord)),
				of(addLoadingRecord(fetchCategoriesLoadingRecord)),
				of(fetchVenuesAsync.request()),
				of(fetchProductsAsync.request()),
				of(fetchCategoriesAsync.request()),
				action$.pipe(
					filter(action =>
						isActionOf(fetchVenuesAsync.success, action) ||
						isActionOf(fetchVenuesAsync.failure, action) ||
						isActionOf(fetchProductsAsync.success, action) ||
						isActionOf(fetchProductsAsync.failure, action) ||
						isActionOf(fetchCategoriesAsync.success, action) ||
						isActionOf(fetchCategoriesAsync.failure, action),
					),
					take(3),
					mergeMap(responseAction => {
						const persistedProducts = state$.value.form.purchasingProcess.form.products.value;
						const persistedStep = state$.value.form.purchasingProcess.form.step.value;
						const persistedPaymentForm = state$.value.form.purchasingProcess.form.paymentForm.value;
						const paymentFormToSet = {
							...persistedPaymentForm,
							acceptStatue: {
								...persistedPaymentForm.acceptStatue,
								value: false,
							},
						};
						if (isActionOf(fetchVenuesAsync.success, responseAction)) {

							// It only finds id on route like /.../:venueId. On route like /.../:venueId/... urlVenueId variable will be null
							// But its intended because if we want to go to other route than "choose-availabilities" on app init we have to have persisted store.
							// If we have some :venueId as param in the URL we will go to first if
							// This if-statement has to ifs inside. First is for :venueId that exists in out venues payload from backend,
							// and the second one is for non-existing venue. This one throws error that venue does not exist
							// If we have persisted store we will go to the first "else if" which will redirect us the route with right param in the URL
							// If we don't have persisted store we will be redirected to route with :venueId param of the first venue from backend payload
							// If backend gives empty array of venues we will be redirected to first step route and form.venue will be null
							// else is fallback which only displays errors and deletes loadingRecords
							const todayDate = DateTime.now().toISODate();
							const persistedVenue: Nullable<SimpleVenue> = state$.value.form.purchasingProcess.form.venue.value ?? null;
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							const venueParam: Nullable<SimpleVenue> = responseAction.payload.data.find(venue => venue.id === urlVenueId);
							const payloadVenues = responseAction.payload.data;
							if (
								isNotNull(urlVenueId) && // urlVenueId !== null
								payloadVenues.length > 0 // backend has returned venues
							) {
								if (isNotNull(venueParam)) { // urlVenueId exists in venues returned from backend
									return merge(
										of(replace(purchasingProcessFormStepRouteDictionary(urlVenueId)[ persistedStep ])),
										of(purchasingProcessFormActions.handleChange({ prop: "venue", value: venueParam })),
										of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
										of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
										of(addLoadingRecord({ loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES })),
										of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
										of(removeLoadingRecord(loadingRecord)),
										of(removeLoadingRecord(fetchVenuesLoadingRecord)),
									);
								} else {
									return merge(
										of(purchasingProcessFormActions.handleChange({ prop: "venue", value: payloadVenues[ 0 ] })),
										of(replace(purchasingProcessFormStepRouteDictionary(payloadVenues[ 0 ].id)[ PurchasingProcessFormStep.AVAILABILITIES ])),
										of(addLoadingRecord({ loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES })),
										of(uiFetchVenueAvailabilitiesDebounce({ venueId: payloadVenues[ 0 ].id, date: todayDate })),
										of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
										of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
										of(removeLoadingRecord(loadingRecord)),
										of(removeLoadingRecord(fetchVenuesLoadingRecord)),
									);
								}
							} else if (isNotNull(persistedVenue)) {
								return merge(
									of(replace(purchasingProcessFormStepRouteDictionary(persistedVenue.id)[ persistedStep ])),
									of(purchasingProcessFormActions.handleChange({ prop: "venue", value: persistedVenue })),
									of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
									of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
									of(addLoadingRecord({ loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES })),
									of(uiFetchVenueAvailabilitiesDebounce({ venueId: persistedVenue.id, date: todayDate })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(fetchVenuesLoadingRecord)),
								);
							} else if (payloadVenues.length > 0) {
								return merge(
									of(replace(purchasingProcessFormStepRouteDictionary(payloadVenues[ 0 ].id)[ persistedStep ])),
									of(purchasingProcessFormActions.handleChange({ prop: "venue", value: payloadVenues[ 0 ] })),
									of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
									of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
									of(addLoadingRecord({ loadableType: LoadableType.INITIAL_LOAD_FETCH_AVAILABILITIES })),
									of(uiFetchVenueAvailabilitiesDebounce({ venueId: payloadVenues[ 0 ].id, date: todayDate })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(fetchVenuesLoadingRecord)),
								);
							} else if (payloadVenues.length === 0) {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
									of(purchasingProcessFormActions.handleChange({ prop: "venue", value: null })),
									of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(fetchVenuesLoadingRecord)),
									of(displayToast({ type: ToastType.ERROR, content: "Brak lokalizacji" })),
								);
							} else {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "date", value: todayDate })), //set date to today date on page load
									of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(fetchVenuesLoadingRecord)),
									of(displayToast({ type: ToastType.ERROR, content: "Nie udało się pobrać lokacji" })),
								);
							}
						} else if (isActionOf(fetchVenuesAsync.failure, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.ERROR, content: "Nie udało się pobrać lokacji" })),
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(fetchVenuesLoadingRecord)),
							);
						}
						if (isActionOf(fetchProductsAsync.success, responseAction)) {
							return merge(
								of(purchasingProcessFormActions.handleChange({ prop: "products", value: mapPersistedProductsToProducts(responseAction.payload.data, persistedProducts) })),
								of(purchasingProcessFormActions.handleChange({ prop: "date", value: DateTime.now().toISODate() })), //set date to today date on page load
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(fetchProductsLoadingRecord)),
							);
						} else if (isActionOf(fetchProductsAsync.failure, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.WARNING, content: "Nie udało się pobrać produktów" })),
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(fetchProductsLoadingRecord)),
							);
						} else if (isActionOf(fetchCategoriesAsync.success, responseAction)) {
							return merge(
								of(purchasingProcessFormActions.handleChange({ prop: "date", value: DateTime.now().toISODate() })), //set date to today date on page load
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(fetchCategoriesLoadingRecord)),
							);
						} else if (isActionOf(fetchCategoriesAsync.failure, responseAction)) {
							return merge(
								of(displayToast({ type: ToastType.WARNING, content: "Nie udało się pobrać kategorii" })),
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(fetchCategoriesLoadingRecord)),
							);
						} else {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(purchasingProcessFormActions.handleChange({ prop: "paymentForm", value: paymentFormToSet })), //set false status-accept checkbox in payment-form
							);
						}
					}),
				),
			);
		}),
	);

export const uiFetchReservationByIdEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchReservationById)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.FETCH_RESERVATION_BY_ID };
			return (
				concat(
					of(addLoadingRecord(loadingRecord)),
					of(fetchReservationByIdAsync.request(action.payload)),
					action$.pipe(
						filter(action => isActionOf(fetchReservationByIdAsync.success, action) || isActionOf(fetchReservationByIdAsync.failure, action)),
						take(1),
						mergeMap(_ => merge(
							of(removeLoadingRecord(loadingRecord)),
						)),
					),
				)
			);
		}),
	);

export const uiFetchAdminReservationsByDateEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchAdminReservationsByDate)),
		debounceTime(400),
		map(action => fetchAdminReservationsByDateAsync.request(action.payload)),
	);

export const uiFetchAdminRoomAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchAdminRoomAvailabilities)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = isNotNull(action.payload.loadableId) ?
				{ loadableId: action.payload.loadableId, loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES } :
				{ loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(uiFetchAdminRoomAvailabilitiesDebounce(action.payload)),
			);
		}),
	);

export const uiFetchAdminRoomAvailabilitiesDebounceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchAdminRoomAvailabilitiesDebounce)),
		debounceTime(400),
		switchMap(action => {
			const loadingRecord: LoadingRecord = isNotNull(action.payload.loadableId) ?
				{ loadableId: action.payload.loadableId, loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES } :
				{ loadableType: LoadableType.FETCH_ADMIN_ROOM_AVAILABILITIES };
			return (
				concat(
					of(addLoadingRecord(loadingRecord)),
					of(fetchAdminRoomAvailabilitiesAsync.request(action.payload)),
					action$.pipe(
						filter(action => isActionOf(fetchAdminRoomAvailabilitiesAsync.success, action) || isActionOf(fetchAdminRoomAvailabilitiesAsync.failure, action)),
						take(1),
						mergeMap(_ => merge(
							of(removeLoadingRecord(loadingRecord)),
						)),
					),
				)
			);
		}),
	);

export const uiFetchRoomAvailabilitiesEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiFetchRoomAvailabilities)),
		debounceTime(400),
		map(action => fetchRoomAvailabilitiesAsync.request(action.payload)),
	);

export const uiAdminDebounceCalculateRoomReservationPrice: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCalculateAdminRoomReservationPrice)),
		map(action => addLoadingRecord({ loadableId: action.payload.roomId, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE })),
	);

export const uiAdminCalculateRoomReservationPriceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCalculateAdminRoomReservationPrice)),
		debounceTime(300),
		switchMap(action =>
			concat(
				of(addLoadingRecord({ loadableId: action.payload.roomId, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE })),
				of(calculateAdminRoomReservationPriceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(calculateAdminRoomReservationPriceAsync.success, action) || isActionOf(calculateAdminRoomReservationPriceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(calculateAdminRoomReservationPriceAsync.success, responseAction)) {
							return merge(
								of(roomReservationFormActions.handleChange({ prop: "price", value: responseAction.payload.data.price })),
								of(roomReservationFormActions.handleChange({ prop: "discountedPrice", value: responseAction.payload.data.discountedPrice })),
								of(roomReservationFormActions.handleChange({ prop: "isDiscountedPriceUnderflow", value: responseAction.payload.data.isDiscountedPriceUnderflow })),
								of(updateReservationFormActions.handleChange({ prop: "price", value: responseAction.payload.data.price })),
								of(updateReservationFormActions.handleChange({ prop: "discountedPrice", value: responseAction.payload.data.discountedPrice })),
								of(updateReservationFormActions.handleChange({ prop: "isDiscountedPriceUnderflow", value: responseAction.payload.data.isDiscountedPriceUnderflow })),
								of(removeLoadingRecord({ loadableId: action.payload.roomId, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE })),
							);
						} else {
							return of(removeLoadingRecord({ loadableId: action.payload.roomId, loadableType: LoadableType.CALCULATE_ROOM_RESERVATION_PRICE }));
						}
					}),
				),
			)),
	);

export const uiCreateReservationEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiCreateReservation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_RESERVATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createReservationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createReservationAsync.success, action) || isActionOf(createReservationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createReservationAsync.success, responseAction)) {

							const payloadData = responseAction.payload.data;

							TagManager.dataLayer({
								dataLayer: {
									event: 'reservation',
									transaction_id: payloadData.payment?.id,
									transaction_value: payloadData.payment?.amount,
									transaction_room: payloadData.room.name,
									transaction_date: moment(payloadData.startDate).format("YYYY-MM-DD"),
									user_email: payloadData.email,
									user_phone: payloadData.phone,
								}
							});

							isNotNull(responseAction.payload.data.payment) && window.open(responseAction.payload.data.payment?.paymentUrl, "_self");
							return merge(
								of(purchasingProcessFormActions.setForm({ form: purchasingProcessFormInitialState })),
								of(removeLoadingRecord(loadingRecord)),
							);
						} else {
							const todayDate = DateTime.now().toISODate();
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							if (isNull(urlVenueId)) return of(removeLoadingRecord(loadingRecord));

							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
							);
						}
					}),
				),
			);
		}),
	);

export const uiCreateAdminReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiCreateAdminReservation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CREATE_RESERVATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(createAdminReservationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(createAdminReservationAsync.success, action) || isActionOf(createAdminReservationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(createAdminReservationAsync.success, responseAction)) {
							return concat(
								of(fetchAdminRoomAvailabilitiesAsync.request({ roomId: responseAction.payload.data.room.id, date: LocaleFromISO(action.payload.date).toFormat("yyyy-MM-dd") })),
								of(fetchAdminVenueAvailabilitiesAsync.request({ venueId: responseAction.payload.data.room.venueId, date: LocaleFromISO(action.payload.date).toFormat("yyyy-MM-dd") })),
								action$.pipe(
									filter(action =>
										isActionOf(fetchAdminRoomAvailabilitiesAsync.success, action) ||
										isActionOf(fetchAdminRoomAvailabilitiesAsync.failure, action) ||
										isActionOf(fetchAdminVenueAvailabilitiesAsync.success, action) ||
										isActionOf(fetchAdminVenueAvailabilitiesAsync.failure, action),
									),
									take(2),
									map(_ => removeLoadingRecord(loadingRecord)),
								),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiUpdateReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateReservation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.id, loadableType: LoadableType.UPDATE_RESERVATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateReservationAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateReservationAsync.success, action) || isActionOf(updateReservationAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateReservationAsync.success, responseAction)) {
							if (isNotNull(action.payload.dateToRefresh)) {
								return concat(
									of(fetchAdminRoomAvailabilitiesAsync.request({ roomId: responseAction.payload.data.room.id, date: LocaleFromISO(action.payload.dateToRefresh).toFormat("yyyy-MM-dd") })),
									of(fetchAdminVenueAvailabilitiesAsync.request({ venueId: responseAction.payload.data.room.venueId, date: LocaleFromISO(action.payload.dateToRefresh).toFormat("yyyy-MM-dd") })),
									of(uiFetchAdminReservationsByDate(LocaleFromISO(action.payload.dateToRefresh).toFormat("yyyy-MM-dd"))),
									action$.pipe(
										filter(action =>
											isActionOf(fetchAdminRoomAvailabilitiesAsync.success, action) ||
											isActionOf(fetchAdminRoomAvailabilitiesAsync.failure, action) ||
											isActionOf(fetchAdminVenueAvailabilitiesAsync.success, action) ||
											isActionOf(fetchAdminVenueAvailabilitiesAsync.failure, action) ||
											isActionOf(fetchAdminReservationsByDateAsync.success, action) ||
											isActionOf(fetchAdminReservationsByDateAsync.failure, action),
										),
										take(3),
										map(_ => removeLoadingRecord(loadingRecord)),
									),
								);
							}
							return of(removeLoadingRecord(loadingRecord));
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiDeleteReservationEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiDeleteReservation)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.DELETE_RESERVATION };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(deleteReservationByIdAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(deleteReservationByIdAsync.success, action) || isActionOf(deleteReservationByIdAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(deleteReservationByIdAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: "Pomyślnie usunięto rezerwacje!" })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiCalculateReservationPriceEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiCalculateReservationPrice)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(calculateRoomReservationPriceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(calculateRoomReservationPriceAsync.success, action) || isActionOf(calculateRoomReservationPriceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(calculateRoomReservationPriceAsync.success, responseAction)) {
							if (isNotNull(action.payload.nextStep)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({ prop: "step", value: action.payload.nextStep })),
									of(purchasingProcessFormActions.handleChange({
										prop: "price",
										value: responseAction.payload.data.price,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: responseAction.payload.data.discountedPrice,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "vatPrice",
										value: responseAction.payload.data.taxes.vatPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "netPrice",
										value: responseAction.payload.data.taxes.netPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "paymentForm",
										value: {
											...state$.value.form.purchasingProcess.form.paymentForm.value,
											maxPeople: {
												...state$.value.form.purchasingProcess.form.paymentForm.value.maxPeople,
												value: action.payload.maxPeople ?? 1,
											},
										},
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: responseAction.payload.data.isDiscountedPriceUnderflow,
									})),
									of(removeLoadingRecord(loadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "price",
									value: responseAction.payload.data.price,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: responseAction.payload.data.discountedPrice,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "vatPrice",
									value: responseAction.payload.data.taxes.vatPrice.toString(),
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "netPrice",
									value: responseAction.payload.data.taxes.netPrice.toString(),
								})),
								of(removeLoadingRecord(loadingRecord)),
							);
						}
						if (isActionOf(calculateRoomReservationPriceAsync.failure, responseAction)) {
							const todayDate = DateTime.now().toISODate();
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							if (isNull(urlVenueId)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: null,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: false,
									})),
									of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas obliczania ceny" })),
									of(removeLoadingRecord(loadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: null,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: false,
								})),
								...(
									responseAction.payload.errors.some(error => error.codeName === ErrorCodeName.ROOM_TIME_RANGES_UNAVAILABLE)
										?
										[]
										:
										[ of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas obliczania ceny" })) ]
								),
								of(removeLoadingRecord(loadingRecord)),
								of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
							);
						}
						return of(removeLoadingRecord(loadingRecord));
					}),
				),
			);
		}),
	);

export const uiCalculateReservationProductsPriceEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiCalculateReservationProductsPrice)),
		debounceTime(500),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE };
			return concat(
				of(calculateRoomReservationPriceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(calculateRoomReservationPriceAsync.success, action) || isActionOf(calculateRoomReservationPriceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(calculateRoomReservationPriceAsync.success, responseAction)) {
							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "price",
									value: responseAction.payload.data.price,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: responseAction.payload.data.discountedPrice,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "vatPrice",
									value: responseAction.payload.data.taxes.vatPrice.toString(),
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "netPrice",
									value: responseAction.payload.data.taxes.netPrice.toString(),
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: responseAction.payload.data.isDiscountedPriceUnderflow,
								})),
								of(removeLoadingRecord(loadingRecord)),
							);
						}
						if (isActionOf(calculateRoomReservationPriceAsync.failure, responseAction)) {
							const todayDate = DateTime.now().toISODate();
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							if (isNull(urlVenueId)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: null,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: false,
									})),
									of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas liczenia ceny produktów" })),
									of(removeLoadingRecord(loadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: null,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: false,
								})),
								...(
									responseAction.payload.errors.some(error => error.codeName === ErrorCodeName.ROOM_TIME_RANGES_UNAVAILABLE)
										?
										[]
										:
										[ of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas obliczania ceny" })) ]
								),
								of(removeLoadingRecord(loadingRecord)),
								of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
							);
						}
						return of(removeLoadingRecord(loadingRecord));
					}),
				),
			);
		}),
	);

export const uiApplyDiscountCodeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiApplyDiscountCode)),
		switchMap(action => {
			const discountCodeLoadingRecord: LoadingRecord = { loadableType: LoadableType.RESERVATION_DISCOUNT_CODE };
			const calculatePriceLoadingRecord: LoadingRecord = { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE };
			const getDiscountCodeValue = (discountCode: Nullable<DiscountCodePayload>): Nullable<ReservationDiscountCode> => {
				if (isNull(discountCode)) return null;
				if (discountCode.type === DiscountCodeType.AMOUNT) {
					return {
						amount: discountCode.amount,
						type: DiscountCodeType.AMOUNT,
						value: action.payload.discountCode ?? null,
					};
				}
				if (discountCode.type === DiscountCodeType.PERCENTAGE) {
					return {
						percentage: discountCode.percentage,
						type: DiscountCodeType.PERCENTAGE,
						value: action.payload.discountCode ?? null,
					};
				}
				return null;
			};
			return concat(
				of(calculateRoomReservationPriceAsync.request(action.payload)),
				of(addLoadingRecord(discountCodeLoadingRecord)),
				of(addLoadingRecord(calculatePriceLoadingRecord)),
				action$.pipe(
					filter(action => isActionOf(calculateRoomReservationPriceAsync.success, action) || isActionOf(calculateRoomReservationPriceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(calculateRoomReservationPriceAsync.success, responseAction)) {
							if (isNotNull(responseAction.payload.data.discountedPrice)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "price",
										value: responseAction.payload.data.price,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: responseAction.payload.data.discountedPrice,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "vatPrice",
										value: responseAction.payload.data.taxes.vatPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "netPrice",
										value: responseAction.payload.data.taxes.netPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "discountCode",
										value: getDiscountCodeValue(responseAction.payload.data.discountCode),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: responseAction.payload.data.isDiscountedPriceUnderflow,
									})),
									of(displayToast({ type: ToastType.SUCCESS, content: "Dodano kod rabatowy!" })),
									of(removeLoadingRecord(calculatePriceLoadingRecord)),
									of(removeLoadingRecord(discountCodeLoadingRecord)),
								);
							}
							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "price",
									value: responseAction.payload.data.price,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: responseAction.payload.data.discountedPrice,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "discountCode",
									value: getDiscountCodeValue(responseAction.payload.data.discountCode),
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: false,
								})),
								of(displayToast({ type: ToastType.ERROR, content: "Kod rabatowy jest nieprawidłowy!" })),
								of(removeLoadingRecord(calculatePriceLoadingRecord)),
								of(removeLoadingRecord(discountCodeLoadingRecord)),
							);
						}
						if (isActionOf(calculateRoomReservationPriceAsync.failure, responseAction)) {
							const todayDate = DateTime.now().toISODate();
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							if (isNull(urlVenueId)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: false,
									})),
									of(removeLoadingRecord(calculatePriceLoadingRecord)),
									of(removeLoadingRecord(discountCodeLoadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: false,
								})),
								of(removeLoadingRecord(calculatePriceLoadingRecord)),
								of(removeLoadingRecord(discountCodeLoadingRecord)),
								of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
							);
						}
						return merge(
							of(purchasingProcessFormActions.handleChange({
								prop: "isDiscountedPriceUnderflow",
								value: false,
							})),
							of(removeLoadingRecord(calculatePriceLoadingRecord)),
							of(removeLoadingRecord(discountCodeLoadingRecord)),
						);
					}),
				),
			);
		}),
	);

export const uiRemoveDiscountCodeEpic: RootEpic = (action$, state$) =>
	action$.pipe(
		filter(isActionOf(uiRemoveDiscountCode)),
		switchMap(action => {
			const discountCodeLoadingRecord: LoadingRecord = { loadableType: LoadableType.RESERVATION_DISCOUNT_CODE };
			const loadingRecord: LoadingRecord = { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(calculateRoomReservationPriceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(calculateRoomReservationPriceAsync.success, action) || isActionOf(calculateRoomReservationPriceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(calculateRoomReservationPriceAsync.success, responseAction)) {
							if (isNotNull(action.payload.nextStep)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "price",
										value: responseAction.payload.data.price,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: responseAction.payload.data.discountedPrice,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "vatPrice",
										value: responseAction.payload.data.taxes.vatPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "netPrice",
										value: responseAction.payload.data.taxes.netPrice.toString(),
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "paymentForm",
										value: {
											...state$.value.form.purchasingProcess.form.paymentForm.value,
											maxPeople: {
												...state$.value.form.purchasingProcess.form.paymentForm.value.maxPeople,
												value: action.payload.maxPeople ?? 1,
											},
										},
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: responseAction.payload.data.isDiscountedPriceUnderflow,
									})),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(discountCodeLoadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "price",
									value: responseAction.payload.data.price,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: responseAction.payload.data.discountedPrice,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "vatPrice",
									value: responseAction.payload.data.taxes.vatPrice.toString(),
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "netPrice",
									value: responseAction.payload.data.taxes.netPrice.toString(),
								})),
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(discountCodeLoadingRecord)),
							);
						}
						if (isActionOf(calculateRoomReservationPriceAsync.failure, responseAction)) {
							const todayDate = DateTime.now().toISODate();
							const urlVenueId = getVenueIdFromURL(state$.value.router.location?.pathname ?? ""); //priority
							if (isNull(urlVenueId)) {
								return merge(
									of(purchasingProcessFormActions.handleChange({
										prop: "discountedPrice",
										value: null,
									})),
									of(purchasingProcessFormActions.handleChange({
										prop: "isDiscountedPriceUnderflow",
										value: false,
									})),
									of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas obliczania ceny" })),
									of(removeLoadingRecord(loadingRecord)),
									of(removeLoadingRecord(discountCodeLoadingRecord)),
								);
							}

							return merge(
								of(purchasingProcessFormActions.handleChange({
									prop: "discountedPrice",
									value: null,
								})),
								of(purchasingProcessFormActions.handleChange({
									prop: "isDiscountedPriceUnderflow",
									value: false,
								})),
								...(
									responseAction.payload.errors.some(error => error.codeName === ErrorCodeName.ROOM_TIME_RANGES_UNAVAILABLE)
										?
										[]
										:
										[ of(displayToast({ type: ToastType.WARNING, content: "Wystąpił błąd podczas obliczania ceny" })) ]
								),
								of(removeLoadingRecord(loadingRecord)),
								of(removeLoadingRecord(discountCodeLoadingRecord)),
								of(uiFetchVenueAvailabilitiesDebounce({ venueId: urlVenueId, date: todayDate })),
							);
						}
						return merge(
							of(purchasingProcessFormActions.handleChange({
								prop: "isDiscountedPriceUnderflow",
								value: false,
							})),
							of(removeLoadingRecord(loadingRecord)),
							of(removeLoadingRecord(discountCodeLoadingRecord)),
						);
					}),
				),
			);
		}),
	);

export const uiUpdateReservationStatusEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiUpdateReservationStatus)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload.reservationId, loadableType: LoadableType.UPDATE_RESERVATION_STATUS };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(updateReservationStatusAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(updateReservationStatusAsync.success, action) || isActionOf(updateReservationStatusAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(updateReservationStatusAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie zmieniono status na "${ reservationStatusDictionary[ action.payload.status ] }"!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiSendReservationInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiSendReservationInvoice)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.SENDING_INVOICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(sendReservationInvoiceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(sendReservationInvoiceAsync.success, action) || isActionOf(sendReservationInvoiceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(sendReservationInvoiceAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wysłano fakturę do klienta!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

export const uiGenerateReservationInvoiceEpic: RootEpic = action$ =>
	action$.pipe(
		filter(isActionOf(uiGenerateReservationInvoice)),
		switchMap(action => {
			const loadingRecord: LoadingRecord = { loadableId: action.payload, loadableType: LoadableType.GENERATE_INVOICE };
			return concat(
				of(addLoadingRecord(loadingRecord)),
				of(generateReservationInvoiceAsync.request(action.payload)),
				action$.pipe(
					filter(action => isActionOf(generateReservationInvoiceAsync.success, action) || isActionOf(generateReservationInvoiceAsync.failure, action)),
					take(1),
					mergeMap(responseAction => {
						if (isActionOf(generateReservationInvoiceAsync.success, responseAction)) {
							return merge(
								of(removeLoadingRecord(loadingRecord)),
								of(displayToast({ type: ToastType.SUCCESS, content: `Pomyślnie wygenerowano fakturę!` })),
							);
						} else {
							return of(removeLoadingRecord(loadingRecord));
						}
					}),
				),
			);
		}),
	);

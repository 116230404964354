/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import React, { useEffect, useState } from "react";
import { Form, FormHookReturnType } from "src/app/types/ui/form.types";
import { mapFormToCalculatePricePayload, paymentFormValidator, PurchasingProcessPaymentForm, PurchasingProcessReducerForm } from "src/app/utils/constants/purchasingProcess.form";
import { Cart } from "src/app/types/api/reservation.types";
import { Badge, Button } from "flowbite-react";
import ReservationCart from "src/app/components/PurchasingProcess/util/Cart/ReservationCart.component";
import Input from "src/app/components/Form/Input.component";
import { SlArrowRight } from "react-icons/sl";
import CheckboxComponent from "src/app/components/Form/Checkbox.component";
import classNames from "classnames";
import { Nullable } from "src/app/types/util.types";
import { BeatLoader, PulseLoader } from "react-spinners";
import { RootState } from "src/app/store/root.reducer";
import { connect } from "react-redux";
import { didLoadingRecordExist } from "src/app/store/features/ui/loading/ui.loading.selectors";
import { LoadableType } from "src/app/types/ui/loading.types";
import { isNotNull, isNull } from "src/app/utils/typeguards";
import { uiApplyDiscountCode, uiRemoveDiscountCode } from "src/app/store/features/ui/reservation/ui.reservation.actions";
import PurchasingProcessSmallButton from "src/app/components/PurchasingProcess/util/PurchasingProcessSmallButton.component";
import { Collapse } from "react-collapse";
import { discountCodeTypeCharDictionary } from "src/app/utils/constants/dictionaries";
import { DiscountCodeType } from "src/app/types/api/discountCode.types";
import { HiOutlineTicket } from "react-icons/hi";
import { HiMiniXMark } from "react-icons/hi2";
import { Link } from "react-router-dom";
import useReservingData from "src/app/utils/hooks/useReservingData";
import PhoneInput from "src/app/components/Form/PhoneInput.component";

type ComponentProps = {
	form: FormHookReturnType<PurchasingProcessReducerForm>
	cart: Cart
	handleProductChange: (productId: number, number: Nullable<number>) => void
}

type Props =
	ReturnType<typeof mapStateToProps>
	& typeof mapDispatchToProps
	& ComponentProps;

function PurchasingProcessPaymentContainer(props: Props) {

	const {
		form: {
			form: {
				paymentForm,
				price,
				discountedPrice,
				isDiscountedPriceUnderflow,
				discountCode: formDiscountCode,
				netPrice,
				vatPrice,
			},
			handleChange,
			handleSubmit,
			setForm,
		},
		cart,
		handleProductChange,
		isCalculatingPrice,
		isDiscountCodeBeingApplied,
		applyDiscountCode,
		isCreatingReservation,
		removeDiscountCode,
	} = props;

	const {
		value: {
			acceptStatue,
			informAboutPromotions,
			name,
			surname,
			phone,
			people,
			email,
			discountCode,

			//Company fields
			isForCompany,
			companyName,
			companyNip,
			companyAddress,
			companyPostCode,
			companyCity,
		},
	} = paymentForm;

	const reservingData = useReservingData();

	const [ isFvCollapsed, toggleFvCollapse ] = useState(isForCompany.value);

	// delete errors on fields on component mount
	useEffect(() => {
		handleChange("paymentForm",
			Object.fromEntries(Object.entries(paymentForm.value).map(entry => {
				const key = entry[ 0 ] as keyof PurchasingProcessPaymentForm;
				return [ key, {
					...paymentForm.value[ key ],
					error: null,
				} ];
			})) as Form<PurchasingProcessPaymentForm>,
		);
	}, []);

	const handleApplyDiscountCode = () => {
		const mappedCalculatePricePayload = mapFormToCalculatePricePayload(props.form.form, reservingData);
		if (isNull(mappedCalculatePricePayload)) return;
		applyDiscountCode(mappedCalculatePricePayload);
	};

	const handlePaymentFormChange = (prop: keyof PurchasingProcessPaymentForm, value: any) => {
		handleChange("paymentForm", {
			...paymentForm.value,
			[ prop ]: { ...paymentForm.value[ prop ], value },
		});
	};

	const handlePaymentFormBlur = (prop: keyof PurchasingProcessPaymentForm) => {
		const error = paymentFormValidator[ prop ](paymentForm.value[ prop ].value as never, paymentForm.value[ prop ].optional, paymentForm.value);
		handleChange("paymentForm", {
			...paymentForm.value,
			[ prop ]: {
				...paymentForm.value[ prop ],
				touched: true,
				error: error,
			},
		});
	};

	const handlePaymentFormChangeWithBlur = (prop: keyof PurchasingProcessPaymentForm, value: any) => {
		const error = paymentFormValidator[ prop ](!paymentForm.value[ prop ].value as never, paymentForm.value[ prop ].optional, paymentForm.value);
		handleChange("paymentForm", {
			...paymentForm.value,
			[ prop ]: {
				...paymentForm.value[ prop ],
				touched: true,
				value: value,
				error: error,
			},
		});
	};

	const handlePaymentFormSubmit = () => {
		let paymentFormError = false;
		handleChange("paymentForm",
			Object.fromEntries(Object.entries(paymentForm.value).map(entry => {
				const key = entry[ 0 ] as keyof PurchasingProcessPaymentForm;
				const error = !paymentForm.value[ key ].disabled ? paymentFormValidator[ key ](paymentForm.value[ key ].value as never, paymentForm.value[ key ].optional, paymentForm.value) : null;
				if (isNotNull(error)) {
					paymentFormError = true;
				}
				return [ key, {
					...paymentForm.value[ key ],
					touched: true,
					error: error,
				} ];
			})) as Form<PurchasingProcessPaymentForm>,
		);

		if (paymentFormError) return;

		handleSubmit();

		handleChange("isFormSubmitted", true);
	};

	const handleFvCheckboxChange = () => {
		const newValue = !isForCompany.value;
		const getError = (fieldName: "companyName" | "companyNip" | "companyAddress" | "companyPostCode" | "companyCity") => {
			if (newValue) return props.form.form.paymentForm.value[ fieldName ].error;
			return null;
		};
		setForm({
			...props.form.form,
			paymentForm: {
				...props.form.form.paymentForm,
				value: {
					...props.form.form.paymentForm.value,
					isForCompany: { ...props.form.form.paymentForm.value.isForCompany, value: newValue },
					companyName: {
						...props.form.form.paymentForm.value.companyName,
						disabled: !newValue,
						error: getError("companyName"),
					},
					companyNip: {
						...props.form.form.paymentForm.value.companyNip,
						disabled: !newValue,
						error: getError("companyNip"),
					},
					companyAddress: {
						...props.form.form.paymentForm.value.companyAddress,
						disabled: !newValue,
						error: getError("companyAddress"),
					},
					companyPostCode: {
						...props.form.form.paymentForm.value.companyPostCode,
						disabled: !newValue,
						error: getError("companyPostCode"),
					},
					companyCity: {
						...props.form.form.paymentForm.value.companyCity,
						disabled: !newValue,
						error: getError("companyCity"),
					},
				},
			},
		});
		toggleFvCollapse(newValue);
	};

	const getDiscountCodeText = () => {
		if (isNull(formDiscountCode.value) || isNull(formDiscountCode.value.value)) return null;
		const amountOrPercentage = formDiscountCode.value.type === DiscountCodeType.AMOUNT ? formDiscountCode.value.amount : formDiscountCode.value.percentage;
		return `${ formDiscountCode.value.value } (-${ amountOrPercentage }${ discountCodeTypeCharDictionary[ formDiscountCode.value.type ] })`;
	};

	const deleteDiscountCode = () => {
		handleChange("discountedPrice", null);
		handleChange("discountCode", null);
		handleChange("isDiscountedPriceUnderflow", false);
		handlePaymentFormChange("discountCode", "");

		const mappedCalculatePricePayload = mapFormToCalculatePricePayload(props.form.form, reservingData);
		if (isNull(mappedCalculatePricePayload)) return;
		mappedCalculatePricePayload.discountCode = "";

		removeDiscountCode(mappedCalculatePricePayload);
	};

	const inputClassNames = "[&>div>input]:placeholder:text-lg [&>div>input]:rounded-xl [&>div>input]:py-3";

	const checkboxLabelClassName = "text-mySecondary-purple-500 font-normal text-sm sm:text-[16px] sm:leading-[17px]";

	return (
		<div className={ classNames(
			"flex gap-10 w-full h-full justify-between",
			"flex-col-reverse",
			"lg:flex-row",
		) }>
			<div className={ classNames(
				"flex flex-col h-max",
				"w-full",
				"lg:w-1/2",
				"xl:w-2/5",
			) }>
				<div className={ classNames(
					"font-medium text-mySecondary-purple-500",
					"text-base mb-4",
					"sm:text-lg sm:mb-7",
				) }>
					PŁATNOŚĆ I DANE
				</div>
				<Input
					formItem={ email }
					name="payment-email"
					className="mb-2"
					inputProps={ {
						type: "email",
						onChange: ({ target: { value } }) => handlePaymentFormChange("email", value),
						onBlur: () => handlePaymentFormBlur("email"),
						placeholder: "adres@nazwa.pl",
						className: inputClassNames,
					} }
					label=""
				/>
				<CheckboxComponent
					formItem={ informAboutPromotions }
					onChange={ () => handlePaymentFormChange("informAboutPromotions", !informAboutPromotions.value) }
					labelClassName={ checkboxLabelClassName }
					className="mb-5"
					name="payment-inform-about-promotions"
					label="Informuj mnie o promocjach i wydarzeniach"
				/>
				<div className="mt-2 text-mySecondary-purple-500 mb-4 font-medium">
					Dane kontaktowe
				</div>
				<div className="w-full flex flex-col justify-stretch gap-2 mb-4">
					<div className="flex gap-2 w-full flex-col sm:flex-row">
						<Input
							formItem={ name }
							name="payment-name"
							className="w-full"
							inputProps={ {
								type: "text",
								onChange: ({ target: { value } }) => handlePaymentFormChange("name", value),
								onBlur: () => handlePaymentFormBlur("name"),
								placeholder: "imię",
								className: inputClassNames,
							} }
						/>
						<Input
							formItem={ surname }
							name="payment-surname"
							className="w-full"
							inputProps={ {
								type: "text",
								onChange: ({ target: { value } }) => handlePaymentFormChange("surname", value),
								onBlur: () => handlePaymentFormBlur("surname"),
								placeholder: "nazwisko",
								className: inputClassNames,
							} }
						/>
					</div>
					<div className="w-full flex items-start gap-2">
						<PhoneInput
							name="payment-phone"
							className={ classNames("w-full", inputClassNames) }
							phoneInputProps={ {
								country: "pl",
							} }
							formItem={ phone }
							onChange={ phone => {
								handlePaymentFormChange("phone", phone);
							} }
							onBlur={ () => handlePaymentFormBlur("phone") }
						/>
						<div className="flex flex-col">
							<Input
								formItem={ people }
								name="payment-name-and-surname"
								className="w-full"
								inputProps={ {
									type: "number",
									onChange: ({ target: { value } }) => handlePaymentFormChange("people", value),
									onBlur: () => handlePaymentFormBlur("people"),
									placeholder: "ilość uczestników",
									className: inputClassNames,
								} }
							/>
							<div className="text-[8px] font-semibold text-myPrimary-purple-400 ml-1">
								Ilość uczestników jest jedynie informacją dla obsługi i nie ma wpływu na cenę rezerwacji
							</div>
						</div>
					</div>
				</div>
				<div className="mb-5 sm:mb-10">
					<CheckboxComponent
						formItem={ isForCompany }
						onChange={ handleFvCheckboxChange }
						labelClassName={ checkboxLabelClassName }
						className="mb-4"
						name="payment-isForCompany"
						label="Chcę otrzymać fakturę VAT"
					/>
					<Collapse isOpened={ isFvCollapsed }>
						<div className="flex flex-col gap-2">
							<Input
								formItem={ companyName }
								name="payment-company-name"
								inputProps={ {
									type: "text",
									onChange: ({ target: { value } }) => handlePaymentFormChange("companyName", value),
									onBlur: () => handlePaymentFormBlur("companyName"),
									placeholder: "Nazwa firmy",
									className: inputClassNames,
								} }
								label=""
							/>
							<Input
								formItem={ companyNip }
								name="payment-company-nip"
								inputProps={ {
									type: "text",
									onChange: ({ target: { value } }) => handlePaymentFormChange("companyNip", value),
									onBlur: () => handlePaymentFormBlur("companyNip"),
									placeholder: "NIP",
									className: inputClassNames,
								} }
								label=""
							/>
							<div className="flex flex-col gap-2">
								<Input
									formItem={ companyAddress }
									name="payment-company-address"
									inputProps={ {
										type: "text",
										onChange: ({ target: { value } }) => handlePaymentFormChange("companyAddress", value),
										onBlur: () => handlePaymentFormBlur("companyAddress"),
										placeholder: "Adres firmy",
										className: inputClassNames,
									} }
									label=""
								/>
								<div className="flex flex-row gap-2">
									<Input
										formItem={ companyPostCode }
										name="payment-company-post-code"
										className="w-1/2"
										inputProps={ {
											type: "text",
											onChange: ({ target: { value } }) => handlePaymentFormChange("companyPostCode", value),
											onBlur: () => handlePaymentFormBlur("companyPostCode"),
											placeholder: "Kod pocztowy",
											className: inputClassNames,
										} }
										label=""
									/>
									<Input
										formItem={ companyCity }
										name="payment-company-city"
										className="w-1/2"
										inputProps={ {
											type: "text",
											onChange: ({ target: { value } }) => handlePaymentFormChange("companyCity", value),
											onBlur: () => handlePaymentFormBlur("companyCity"),
											placeholder: "Miasto",
											className: inputClassNames,
										} }
										label=""
									/>
								</div>
							</div>
						</div>
					</Collapse>
				</div>
				<CheckboxComponent
					formItem={ acceptStatue }
					onChange={ () => {
						if (!acceptStatue.value) {
							handlePaymentFormChangeWithBlur("acceptStatue", !acceptStatue.value);
						} else {
							handlePaymentFormChange("acceptStatue", !acceptStatue.value);
						}
					} }
					name="payment-acceptStatue"
					labelClassName={ checkboxLabelClassName }
					label={
						<span>
							<span>Akceptuję</span>
							<Link
								to="https://kakadukaraoke.com/regulamin/"
								target="_blank"
								className="text-myPrimary-orange-500 underline mx-1"
								onClick={ e => e.stopPropagation() }
							>
								regulamin
							</Link>
							<span>serwisu</span>
						</span>
					}
				/>
				<div className="text-xs text-gray-400 mb-5">
					Dodatkowo wyrażam zgodę na przetwarzanie moich danych osobowych (imię
					i nazwisko, e-mail, nr telefonu) przez Administratora Danych Osobowych (ADO).
				</div>
				<Button
					color="orange-full"
					className="max-w-max [&>span]:text-xl [&>span]:!py-[4px] [&>span]:!px-4 mb-5 rounded-xl"
					disabled={ isCalculatingPrice || !acceptStatue.value }
					onClick={ handlePaymentFormSubmit }
				>
					{
						isCalculatingPrice || isCreatingReservation
							?
							<div className="mx-auto">
								<BeatLoader size={ 10 } color="white"/>
							</div>
							:
							<div className="flex items-center">
								<span>płacę</span>
								<SlArrowRight className="ml-3 h-3 w-3"/>
							</div>
					}
				</Button>
				<div className="flex items-center gap-3 text-myPrimary-orange-500 text-[10px] underline">
					{/*<a href="#">Polityka zwrotów</a>*/}
					<a href="https://kakadukaraoke.com/polityka-prywatnosci/" target="_blank">Polityka prywatności</a>
					<a href="https://kakadukaraoke.com/regulamin/" target="_blank">Regulamin</a>
				</div>
			</div>
			<ReservationCart
				title="PODSUMOWANIE ZAMÓWIENIA"
				cart={ cart }
				handleProductChange={ handleProductChange }
				className={ classNames(
					"!w-full",
					"lg:!w-1/2",
					"xl:!w-2/5",
				) }
			>
				<div className="flex flex-col gap-2 mt-10 mb-2">
					<h2>Kod rabatowy</h2>
					<div className="flex items-center justify-between gap-10">
						<Input
							formItem={ discountCode }
							name="payment-discount-code"
							className="w-full"
							inputProps={ {
								type: "text",
								className: "w-full [&>div>input]:rounded-[0px] sm:[&>div>input]:placeholder:tracking-[0.5rem] [&>div>input]:py-1",
								onChange: ({ target: { value } }) => handlePaymentFormChange("discountCode", value),
								onBlur: () => handlePaymentFormBlur("discountCode"),
								placeholder: "KOD RABATOWY",
							} }
						/>
						<PurchasingProcessSmallButton
							isLoading={ isDiscountCodeBeingApplied }
							onClick={ handleApplyDiscountCode }
							disabled={ discountCode.value === "" }
						>
							<span>Aktywuj</span>
						</PurchasingProcessSmallButton>
					</div>
					<Badge
						color="success"
						className={ classNames("mr-auto", { "opacity-0": isNull(getDiscountCodeText()) }) }
					>
						<div className="flex items-center gap-1">
							<HiOutlineTicket className="h-4 w-4"/>
							<span>
								{ getDiscountCodeText() }
							</span>
							<HiMiniXMark
								onClick={ deleteDiscountCode }
								className="cursor-pointer"
							/>
						</div>
					</Badge>
				</div>
				<div className="flex flex-col gap-2 text-mySecondary-purple-500 text-sm">
					<div className="flex items-center justify-between">
						<span>Cena netto</span>
						<span>{ `${ netPrice.value } zł` }</span>
					</div>
					<div className="flex items-center justify-between">
						<span>Podatek VAT</span>
						<span>{ `${ vatPrice.value } zł` }</span>
					</div>
				</div>

				{/* Separator */ }
				<hr className="h-[1px] w-full bg-gray-400 my-4"></hr>

				<div className="flex flex-col gap-4">
					<div className="flex items-start justify-between font-extrabold text-mySecondary-purple-500 pb-10">
						<div className="text-lg">
							Razem
						</div>
						<div className="relative">
							{
								isCalculatingPrice &&
                                <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 flex">
                                    <PulseLoader color="#1b000b" size={ 10 }/>
                                </div>
							}
							<div className={ classNames(
								"text-3xl",
								{ "opacity-20": isCalculatingPrice },
							) }>
								{
									isNotNull(discountedPrice.value) &&
                                    <span>
									{ `${ discountedPrice.value } ZŁ` }
								</span>
								}
								<span className={ classNames(
									{ "text-sm line-through ml-2 opacity-50": isNotNull(discountedPrice.value) },
								) }>
								{ `${ price.value } ZŁ` }
							</span>
							</div>
						</div>
					</div>
					{
						isDiscountedPriceUnderflow.value &&
						<span className="text-sm">
								Opłata w wysokości 1zł za obsługę rezerwacji online, zwrot możliwy podczas wizyty w lokalu.
						</span>
					}
				</div>

			</ReservationCart>
		</div>
	);
}

const mapStateToProps = (state: RootState) => ({
	isCalculatingPrice: didLoadingRecordExist(state, { loadableType: LoadableType.CALCULATE_RESERVATION_PRICE }),
	isDiscountCodeBeingApplied: didLoadingRecordExist(state, { loadableType: LoadableType.RESERVATION_DISCOUNT_CODE }),
	isCreatingReservation: didLoadingRecordExist(state, { loadableType: LoadableType.CREATE_RESERVATION }),
});

const mapDispatchToProps = {
	applyDiscountCode: uiApplyDiscountCode,
	removeDiscountCode: uiRemoveDiscountCode,
};

export default connect(mapStateToProps, mapDispatchToProps)(PurchasingProcessPaymentContainer);

/*
 * Copyright (C) WeAstronauts Software - All Rights Reserved 2024.
 * Unauthorized copying of this file, via any medium is strictly prohibited
 * Proprietary and confidential
 */

import { HiCheck } from "react-icons/hi2";
import { Form } from "src/app/types/ui/form.types";
import Input from "src/app/components/Form/Input.component";
import React from "react";
import { PurchaseVoucherForm } from "src/app/containers/Voucher/PurchaseVoucher.container";
import classNames from "classnames";
import { isNotNull, isNull } from "src/app/utils/typeguards";

type Props = {
	label: string
	isSelected: boolean
	onClick: () => void
	isCustom?: boolean
	form: Form<PurchaseVoucherForm>
	handleChange: <K extends keyof PurchaseVoucherForm>(prop: K, value: PurchaseVoucherForm[K]) => void
	handleBlur: (prop: keyof PurchaseVoucherForm) => void
};

function PriceCard(props: Props) {

	const {
		label,
		isSelected,
		onClick,
		isCustom = false,
		form,
		handleChange,
		handleBlur,
	} = props;

	const inputClassNames = "[&>div>input]:placeholder:text-lg [&>div>input]:rounded-xl [&>div>input]:py-3";

	const isCustomPriceError = isCustom && isSelected && isNotNull(form.price.error);

	return (
		<div className="relative">
			<button
				type="button"
				onClick={ onClick }
				className={
					classNames(
						"relative w-full bg-white text-gray-800  p-5 rounded-xl transition-all duration-200 min-h-[134px] border border-gray-300 mb-2",
						{ "hover:shadow-md": !isSelected },
						{ "border-primary-600 border-[3px] shadow-lg scale-[102%] xl:scale-105" : isSelected },
					)
				}
			>
				<div className="flex flex-col items-center space-y-2">
					<span className="text-2xl font-bold">{ label }</span>
					{
						(isCustom && !isSelected) &&
                        <span className="text-sm opacity-75">Dowolna kwota</span>
					}
					{
						(isCustom && isSelected) &&
                        <Input
                            formItem={ form.price }
                            name="price"
                            className="w-full"
                            inputProps={ {
								type: "number",
								onChange: ({ target: { value } }) => handleChange("price", value),
								onBlur: () => handleBlur("price"),
								placeholder: "kwota (zł)",
								className: inputClassNames,
							} }
                            displayErrorMessage={ false }
                        />
					}
					{
						isSelected && (
							<div className="absolute top-3 right-3 !mt-0 text-primary-600">
								<HiCheck size={ 20 }/>
							</div>
						)
					}
				</div>
			</button>
			{
				isCustomPriceError &&
                <span className="text-sm text-red-600 dark:text-red-500 font-medium" role="input-error">{ form.price.error }</span>
			}
			{
				isCustom && isSelected && isNull(form.price.error) &&
                <span className="text-sm text-gray-500 font-medium">
                    Minimalna kwota vouchera to 50 zł
                </span>
			}
		</div>
	);
}

export default (PriceCard);